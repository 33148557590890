.body {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align:center; 
    color:#00BFFF; 
    font-family: Corbel Light;
    background: var(--login-background);
	background-position: center;
	background-size: contain;
	background-repeat: repeat;
	background-color: rgb(2, 2, 14);
}

.content {
    padding: 30px;
    border-radius: 30px;
    background-color: rgba(0,0,0,.18);
}

.pageTitle {
    font-size: 32pt;
    margin: auto auto 30px;
	color: white; /* #0CAC25 */
    font-style: italic;
}

.label {
    min-width: 110px;
    text-align: left;
}

.inputRow {
    color: white;
	text-align: center;
	margin: 5px;
}

#submit {
    margin-bottom: 25px;
}

.link {
    font-size: 12pt;
	text-decoration: none;
	border-bottom: 1px solid #505050;
	padding: 1px;
	margin: 3px;
	color:#00BFFF;
}
.recoveredPwdHeader {
    color: white;
    margin-bottom: 3px;
}
#pwdLoc {
    width: 250px;
    height: 30px;
    border: 1px solid #00BFFF;
    color: white;
    margin: auto;
}

.notification {
    margin-top: 10px;
}

@media (max-width: 500px) {
	.body {
		background: var(--login-background-portrait);
	}
}