.fullSize {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

html, .body {
    height: 100%;
    width: 100%;
    margin: 0;
    overflow: hidden;
}

.body {
    display: flex;
    flex-direction: column;
}

.main {
    width: 100%;
    height: 100%;
    display: flex;
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

#header {
    width: 100%;
}

#content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: auto;
}

@media (max-width: 768px) {
    info-box-component {
        display: none;
    }
}

.whiteText {
    color: white;
}

p {
    /* word-wrap: break-word; */
    font-size: 10pt;
    /* max-height: 2rem; */
}

@font-face {
	font-family: 'Corbel Light';
	src: url("../resources/fonts/Corbel\ Light.TTF") format("truetype");
}

@font-face {
    font-family: "Abuget";
    src: url("../resources/fonts/Abuget.ttf") format("truetype");
}

@font-face {
    font-family: "Corbel";
    src: url("../resources/fonts/Corbel.ttf") format("truetype");
}