.container {
    z-index: 1;
    margin-bottom: 8px;
    cursor: pointer;
}

.line {
    height: 1px;
    width: 12px;
    background-color: black;
    margin-top: 23px;
}

.noteContainer {
    height: 46px;
    width: calc(100% - 12px);
    border: 1px solid;
    background-color: rgba(211, 211, 211, 60%);
    position: relative;
    border-radius: 3px;
    padding: 3px;
}

.plusNoteContainer {
    position: relative;
    margin-right: 0px;
    margin-left: 12px;
}

.title {
    font-size: 10.5pt;
    font-weight: bold;
    font-style: italic;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    min-width: 0;
}

.text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 8pt;
}

.stickyNote {
    background-color: rgba(255, 247, 209, 60%);
}

.plusContainer {
    border: 1px solid;
    border-radius: 3px;
    background-color: lightskyblue;
    width: 21px;
    height: 21px;
}

.plus {
    font-size: 16pt;
    margin-top: -8.4px;
    margin-left: 3.1px;
}

.select {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    font-size: 10pt;
    position: absolute;
    cursor: pointer;
}

.select:disabled {
    opacity: 0;
}

.to85WidthAnim { /* Appended to select once made visible */
    animation: to85Width .5s;
}

@keyframes to85Width {
    0% { width: 40px }
    100% { width: 85%; }
}

.removeConnection {
    position: absolute;
    bottom: 6%;
    left: 100%;
    background-image: url(../../resources/removeIcon.png);
    background-size: contain;
    width: 18px;
    height: 18px;
    opacity: 0;
    transition: .25s ease;
    z-index: 3;
    cursor: pointer;
}

.removeConnection:hover {
    background-image: url(../../resources/removeIconHover.png);
    background-size: contain;
}

.noteContainer:hover .removeConnection {
    left: calc(100% - 22px);
    opacity: 1;
}