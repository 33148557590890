.configs {
    background: white;
    display: flex;
    align-items: center;
}

#searchBar { /* Container for searchingFor */
    border-bottom: 1px solid grey;
    padding: 8px;
    background: lightgrey;
}

#searchInputContainer {
    position: relative;
    width: 100%;
}

#searchInputContainer:after {
    content: "";
    position: absolute;
    right: 6px;
    top: 9px;
    background-image: url(../../resources/dropdownArrow.png);
    background-size: contain;
    width: 14px;
    height: 14px;
    margin-left: -30px;
    padding: .1em;
    pointer-events: none;
    cursor: pointer;
}

#searchInput {
    margin: 1px 0 0 1px;
    padding: 1px 4px;
    font-size: 16px;
    width: 100%;
}

#searchInput::-webkit-calender-picker-indicator {
    display: none;
}

#searchInputClear {
    position: absolute;
    top: 10px;
    right: 24px;
    width: 12px;
    height: 12px;
    cursor: pointer;
}

#searchInputClear::after, #searchInputClear::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    transform: rotate(45deg);
    background-color: grey;
    border-radius: 2px;
}

#searchInputClear::after {
    transform: rotate(45deg);
}

#searchInputClear::after {
    transform: rotate(-45deg);
}

#searchInput:focus-visible {
    outline: none;
}