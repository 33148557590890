.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    flex-direction: column;
}

.loadingIcon {
    background: url("../../resources/loadingImages/journalOpen2.gif");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 400px 300px;
    width: 400px;
    height: 300px;
    margin-top: -35px;
}

.closedIcon {
    background: url("../../resources/loadingImages/journalClosed.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 400px 300px;
    width: 400px;
    height: 300px;
    margin-top: -35px;
}

.text {
    margin-top: -35px;
    color: black;
    transition: 1s;
    max-width: 500px;
    padding: 0 10px;
    text-align: center;
}

.link {
    color: #00BFFF;
    transition: 1s;
}

.link:hover {
    cursor: pointer;
}

*, *::before, *::after {
	box-sizing: border-box;
}

.thoughtwebContainer {
    position: absolute;
    background-image: radial-gradient(rgb(152 152 152 / 30%) 30%, transparent 60%);
    z-index: 4;
    padding: 60px;
    border-radius: 8px;
    text-align: center;
    width: max-content;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
}

#thoughtweb {
    z-index: 5;
    filter: drop-shadow(0px 0px 4px rgb(0 0 0 / .8));
}

.linearLines {
    filter: drop-shadow(0px 0px 2px rgb(0 0 0 / 0.4));
}

#preloadingLineSvg {
    position: absolute;
}

.preloadingText {
    position: absolute;
    left: 50%;
    translate: -50%;
    color: whitesmoke;
    font-family: sans-serif;
    text-shadow: 0px 0px 8px darkslategray;
}

@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}

.loadingCenterContainer {
    width: 300px;
    height: 226px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
	padding: 2rem;
    /* z-index: 0; */
}

.loadingCenterContainer::before {
    content: '';
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #399953;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#399953, #399953), linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33), linear-gradient(#377af5, #377af5);
    animation: rotate 4s linear infinite;
}
	
.loadingCenterContainer::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: white;
    border-radius: 5px;
}