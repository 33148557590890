.main {
    height: 100%;
    position: relative;
    overflow: auto;
    background: url(../../resources/journalBackground2.png);
    background-size: 600px 450px;
    background-repeat: repeat;
    background-attachment: local;
    box-shadow: inset 0 0 100px rgba(32, 32, 32, 0.386);
}

canvas {
    position: absolute;
    height: 100%;
    z-index: 0;
}