html, body {
    overflow: hidden;
}

#content {
    width: 100%;
    height: 100%;
}

.form {
    padding: clamp(10px, 4vh, 35px);
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    background-color: whitesmoke;
    container-type: inline-size;
    container-name: form;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

img { /* Notebook icon */
    margin-top: 2px;
    width: 20px;
    height: 24px;
}

#notebookIcon {
    max-width: 22px;
    max-height: 22px;
    margin-left: 3px;
}

#notebook {
    width: 100%;
    min-width: 156px;
    margin-left: 8px;
    /* background-image: url(../../resources/notebook2.png);
    background-size: cover;
    background-position: 50px 400px;
    background-color: rgba(0, 0, 0, .1);
    padding: 0;
    margin-bottom: 6px; */
}

.thoughtWallLink {
    white-space: nowrap;
    padding-bottom: 0;
}

.editorAndConnections {
    display: flex;
    flex: 1;
    height: 80%;
}

#editor {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 15px;
    border: 1px solid;
    transition: 1s ease;
    flex: 1; /* Fills space between connections and editor */
    border-radius: 5px;
}

.editorMainColor {
    background-color: white;
}

.editorStickyColor {
    background-color: rgb(255, 247, 209);
}

.editorTextInputs {
    border: none;
    outline: none;
    padding-bottom: 4px;
    background-color: transparent;
}

#title {
    font: 14pt Corbel;
    font-weight: bold;
    padding-left: 2px;
    padding-bottom: 0;
    flex: 1;
}

#dateCreated {
    display: inline;
    padding-left: 3px;
    margin-right: -2px;
    color: gray;
}

#text, #quotes {
    font-size: 16px;
}

#text {
    flex: 2;
    resize: none;
}

#quotes {
    height: 10vh;
    font-family: 'Corbel Light';
    padding: 0;
    resize: none;
}

.toggleQuotes {
    position: relative;
    /* bottom: 110px; */
    shape-outside: content-box;
    float: right;
}

.radioRow {
    position: relative;
    display: flex;
    z-index: 3;
}

#connectionsRow {
    overflow-y: auto;
    overflow-x: hidden;
    flex: .35;
    max-width: 250px;
    min-width: 100px;
    position: relative;
}

.canvasContainer {
    display: inline-block;
    padding: 5px;
    border: 1px solid transparent;
    transition: .5s ease;
    position: relative;
    /* border-radius: 2px; */
}

.radios {
    transition: .5s ease;
}

#mainButton, #stickyButton {
    flex: 1;
    min-width: 0;
    overflow: hidden;
}

#mainButton {
    background-color: white;
    border-color: lightgrey;
    color: grey;
    margin-right: 4px;
}

#stickyButton {
   background-color: rgb(255, 247, 209);
   border-color: rgb(241, 226, 151);
   color: grey;
}

#stickyButton:hover {
    background-color: rgb(253, 243, 192);
}

#addUpdate {
    position: relative;
    margin-top: 24px;
    bottom: 0%;
    padding: 1px 16px;
    width: max-content;
}

.addUpdateEnabled {
    background-color: darkblue;
    border-color: darkblue;
}

.addUpdateDisabled {
    background-color: #4f4fa3;
    border-color: #4f4fa3;
    color: lightgrey;
}

#connectedToLabel {
    rotate: 90deg;
    font-size: 10vh;
    font-family: Corbel Light;
    color: lightgray;
    position: absolute;
    left: calc(50% + 2px); /* Adds 8px to account for connecting-line gap of EditorConnections to align in center */
    top: 50%;
    translate: -50% -50%;
    z-index: 0;
}

.typeDescription {
    opacity: 0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px 0;
    transition: .5s ease;
    z-index: -1;
    pointer-events: none;
}

#mainButton .typeDescription {
    background-color: white;
    border-top: 1px solid lightgray;
}

#stickyButton .typeDescription {
    background-color: rgb(255, 247, 209);
    border-top: 1px solid rgb(241, 226, 151);
}

#mainButton:hover .typeDescription, #stickyButton:hover .typeDescription {
    opacity: 1;
    top: 0;
    translate: 0 -100%;
    pointer-events: auto;
}

@keyframes slideUp {
    0% { translate: 0 0 }
    100% { translate: 0 -100% }
}

@container form (max-width: 450px) {
    .editorAndConnections {
        flex-direction: column;
        overflow-x: hidden;
        min-height: 350px;
    }

    .editorAndConnections #connectionsRow {
        display: flex;
        max-width: unset;
        overflow-y: hidden;
        overflow-x: auto;
        flex: unset;
    }

    .editorAndConnections #connectionsRow .connectionContainer {
        flex-direction: column;
        min-width: 125px;
        margin-bottom: 0;
    }

    .editorAndConnections #connectionsRow .connectionContainer.add {
        min-width: 54px;
    }

    .editorAndConnections #connectionsRow .connectionContainer .connectionLine {
        flex-direction: column;
        height: 12px;
        width: 1px;
        margin: 0 auto;
        translate: -6px;
    }

    .editorAndConnections #connectionsRow #connectedToLabel {
        rotate: 0deg;
        top: -15px;
        left: 0;
        translate: 0;
        font-size: 9vh;
        display: none;
    }
}