html {
	height: 100%;
	width: 100%;
	margin: 0;
	background-position: center;
	background-size: contain;
	background-repeat: repeat;
	background-color: rgb(2, 2, 14);
}

.body {
    width: 100%;
	height: 100%;
	margin: 0;
	background: var(--login-background);
	background-position: center;
	background-size: contain;
	background-repeat: repeat;
	background-color: rgb(2, 2, 14);
	flex-direction: column;
	position: relative;
}

#journal {
	position: absolute;
	width: 273px;
	height: 360px;
	background-image: url("../../resources/justNotebook.png");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 273px 359.8px;
	margin-top: 5px;
}

.title {
	font-family: 'Abuget';
	/* font-size: clamp(36pt, 5vw, 5vw); */
	font-size: 72px;
	position: absolute;
	top: 10%;
	color: white;
	z-index: 2;
}

.form {
	position: relative;
}

.inputRow {
	text-align: center;
	margin: 15px;
}

.inputs {
	width: 154px;
}

.label {
	color: white;
	font-family: Corbel Light;
	font-size: 11pt;
	background-color: gray;
	padding: 0px 1px;
}

input[type="text"], input[type="password"] {
	font-family: Corbel Light;
}

input[list]::-webkit-calendar-picker-indicator,
input[list]::-webkit-list-button {
    opacity: 0 !important
}

.linksContainer {
	white-space: nowrap;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	margin-top: 98px;
}

#oauthLinks {
	text-align: center;
	margin-bottom: 18px;
}

.oauthLinkContainer {
	display: inline-flex;
	background-color: whitesmoke;
	border: 1px solid darkgray;
	border-radius: 8px;
	padding: 4px 8px;
	cursor: pointer;
}

.oauthLinkContainer:active {
	border-color: gray;
	background-color: #eaeaea;
}

.oauthLinkContainer:hover {
	background-color: rgb(230, 230, 230);
}

.oauthLinkText {
	text-decoration: none;
	vertical-align: middle;
	margin-left: 8px;
	font-size: 14px;
	color: #828282;
}

.googleIcon {
	background-image: url(../../resources/google_240x240.png);
	background-size: contain;
	width: 22px;
	height: 22px;
}

.link {
	font: 12pt Corbel Light;
	text-decoration: none;
	border-bottom: 1px solid #505050;
	padding: 1px;
	margin: 4px;
	color: #00BFFF;
}

.submitContainer {
	position: absolute;
	width: 100%;
	top: 100%;
	text-align: center;
}

.flexCenter {
	display: flex;
	align-items: center;
	justify-content: center;
}

.textAlignCenter {
	text-align: center;
}

@media (max-width: 500px) {
	.body {
		background: var(--login-background-portrait);
	}
}